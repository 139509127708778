// import logo from './logo.svg';
// import './App.css';

import { useEffect, useState } from 'react'

function App() {

    const [balance, setBalance] = useState(0)

    const tronWeb = window.tronWeb

    useEffect(
        () => {
            this.state.address && setBalance(tronWeb.trx.getBalance(this.address))
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )



    return (
        <div className="App">
            123123

            <p>trx余额： {balance}</p>
        </div>
    );
}

export default App;
