import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const waitForGlobal = async () => {
    // 1.check variable, 坚持tronweb是否已经加载
    if (window.tronWeb) {
        let tronWeb = window.tronWeb
        // check node connection 检测所需api是否都可以联通
        const nodes = await tronWeb.isConnected()
        const connected = !Object.entries(nodes).map(([name, connected]) => {
            if (!connected) {
                console.error(`Error: ${name} is not connected`)
            }
            return connected
        }).includes(false)
        if (connected) {
            // 3。如果一切正常，启动react应用
            const root = ReactDOM.createRoot(document.getElementById('root'));
            root.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            );
        } else {
            console.error(`Error: TRON node is not connected`)
            console.error(`wait for tronLink`)
            setTimeout(async () => {
                await waitForGlobal();
            }, 100)
        }
    } else {
        // 如果坚持到没有注入tronWeb对象，等待100ms后重新检测
        console.error('wait for tronLink')
        setTimeout(async () => {
            await waitForGlobal();
        }, 100)
    }
}

waitForGlobal().then();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
